<template>
  <div class="personal_info">
<!--    <input type="file" hidden ref="inputFile" @change="inputChange" />-->

    <van-cell title="头像"  is-link class="top-img">

      <img-upload
        class="upload"
        v-model="imgid"
        :bg="img"
        :maxsize="10"
        :previewImg="true"
        fileTypeCode="1001"
        :preview="false"
        :deletable="false"
        @change="handleChangeLogo"
      />

    </van-cell>
    <van-cell title="姓名" :is-link="userInfo.verifyStatus!='3'"  clickable :class="`title-${userInfo.verifyStatus}`"  :value="username" @click="toPersonal" />

    <van-cell title="手机号"   clickable  :value="phone" />



  </div>
</template>

<script>
import { mapGetters,mapActions} from "vuex";
import img_upload from '@/page/verified/components/img_upload'
import {updateUserInfo} from "@/api/res-user";
import { Toast } from 'vant'
export default {
  name: 'personal_info',
  data () {
    return {
        img:require('@/assets/img/avatar.png'),
        imgid:'',
    }
  },
  components:{
    'img-upload':img_upload
  },

  computed: {
    ...mapGetters(["userInfo"]),

    phone(){
        return ` ${ this.userInfo.phone.substring(0, 3) }****${
                        this.userInfo.phone.substring(7, 11)
                        }`
    },
    username(){
     /*  let name=this.userInfo.realName? this.userInfo.realName :'去实名'
      return name */
      let map={'1':'去实名','2':'实名中','3':this.userInfo.realName,'4':'实名失败'}
      return map[this.userInfo.verifyStatus]
    }

  },

  watch:{
    imgid(id){
      this.img=`${process.env.VUE_APP_OBS_PREFIX}/${id}`
    }
  },


  mounted () {
    document.title = '个人信息'
    if(this.userInfo.profilePictureAttmId){
      this.imgid=this.userInfo.profilePictureAttmId
      this.img=`${process.env.VUE_APP_OBS_PREFIX}/${this.userInfo.profilePictureAttmId}`
    }

  },

  methods:{
    ...mapActions(["GetInfo"]),
    handleChangeLogo(id) {
      const {userInfo} = this;
      Toast.loading({
        message: '提交中',
        forbidClick: true,
      });
      updateUserInfo({
        profilePictureAttmId: id,
        signature: userInfo.signature,
      }).then(() => {
        this.GetInfo();
      });

      Toast.clear()
      Toast("更换成功");
    },


    toPersonal(){
      if(this.userInfo.verifyStatus==='1'){
        this.$router.push(`/verified_personal`)
      }else if(this.userInfo.verifyStatus==='4' || this.userInfo.verifyStatus==='2'){
        this.$router.push(`/personal_result`)
      }
    }

  },




}
</script>

<style scoped lang="scss">
.personal_info{
  width: 100vw;
  min-height: calc(100vh - 56px);
  background: #F6F6F6;
  padding: 16px;
  :nth-child(1){
    border-radius: 4px 4px 0 0 ;
  }
  :last-child{
    border-radius:  0 0 4px 4px;
  }

  .title-1::v-deep{
    .van-cell__value{
      span{
        color: #1abc9c !important;
      }
    }
  }

  .title-2::v-deep{
    .van-cell__value{
      span{
        color: #FAAD29 !important;
      }
    }
  }

  .title-4::v-deep{
    .van-cell__value{
      span{
        color: #FC4D4F !important;
      }
    }
  }

  ::v-deep{
    .van-cell{
      height: 57px;
      display: flex;
      align-items: center;
      :nth-child(1){
        display: flex;
        align-items: center;
        >span{
          font-size: 16px;
          //font-weight: 500;
          color: #333333;
        }
      }
      :nth-child(2){
        display: flex;
        align-items: center;
        justify-content: flex-end;
        >span{
          font-size: 16px;
          //font-weight: 500;
          color: #999999;
        }
      }
    }
  }
}


.upload{
  border: none;
  background: #FFFFFF;
  margin: 0;
}

.upload::v-deep{
  img{
    width: 42px;
    height: 42px;
    border-radius: 42px!important;
  }
  .van-uploader__wrapper{
    width: 42px;
    height: 42px;
  }
  .van-uploader__preview-image{
    width: 42px;
    height: 42px;
  }
  .van-uploader__input{
    width: 42px;
    height: 42px;
  }
}
</style>
